import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"
import TtaCoverflowEffect from "../../components/standProj/TtaCoverflowEffect"

import standProjMain from "../../img/ttastandProjMain.png"
import standMainImg03 from "../../img/tta_content.jpg"
import tta_1 from "../../img/tta_1.png"
import tta_2 from "../../img/tta_2.png"
import tta_3 from "../../img/tta_3.png"

const TtaStand = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="interStandWrap">
      <div className="sectionWrap StandOverview ttaOverview">
        <p className="standProjMain ttastandProjMain">
          <img src={standProjMain} alt="" />
        </p>
        <div className="textWrap">
          <h2>
            TTA <span>standard</span>
          </h2>
          <p className="bodyText">
            엠투엠테크는 한국승강기안전공단·한국승강기학회와 협력해 2020년 말
            한국정보통신기술협회(TTA) 단체표준 세 건을 제정했습니다. 이와 함께,
            TTA가 시행하고 있는 ‘중소·중견기업을 위한 ICT 표준·기술 자문서비스’
            사업에서 엠투엠테크의 성공적인 표준화 활동과 탁월한 자문 성과를
            인정받아 "우수 자문 사례상"을 수상하였습니다. 현재 세 개의 표준은
            국가 표준으로 승인 받기 위해 국립전파연구원(RRA)과 표준 위원회 별
            절차를 진행하고 있습니다.
          </p>
        </div>
      </div>
      <div className="sectionWrap tta">
        <div className="textWrap">
          <h2>표준의 효과</h2>
          <p>
            <img src={standMainImg03} alt="" />
          </p>
          <p className="bodyText">
            본 표준은 승강기와 IT 산업 간 디지털 융합된 표준으로, 승강기 산업
            분야에서 안전과 국민 편익, 제품 상용화 측면에서 활용도가 높으며,
            로봇 배송 등 건물 내 수직 배송 융합 신 산업 생태계 확장성에도
            기여했습니다. 특히, 국제 표준화 기구에 제안/채택/승인된 표준으로
            해외 표준 특허를 출원하였으며, 건물 내 표준 플랫폼으로 확장하고 해외
            시장 진출을 용이하게 하여 산업 활성화에 기여했습니다. 그리고, TTA
            단체표준으로 제정되었을 뿐만 아니라 국가 표준으로 심의 중이며,
            2022년 상반기에 제정될 예정입니다.
          </p>
        </div>
      </div>

      <div className="sectionWrap elsaServices ttaStandards">
        <div className="textWrapMain">
          <h2>승강기 원격 모니터링을 위한 사물 인터넷 게이트웨이 표준</h2>
        </div>
        <ul>
          <li className="elsaServices0 ttaServicesLi">
            <div className="ttaStandardsImg elsaServicesImg0">
              <img src={tta_1} alt="" />
            </div>
            <div className="textWrap">
              <h2>구조 및 기능요구사항에 대한 표준</h2>

              <p className="bodyText">
                이 표준은 승강기 원격 모니터링을 위한 사물 인터넷 게이트웨이
                구조에 대해서 설명하고 이를 구성하는 승강기, 게이트웨이, 서버들
                간에 인터페이스 및 요구사항에 대하여 기술한다.
              </p>
              {/* <ol>
                <li>
                  <span>연결성</span>
                  <span>– IoT Stack에 의한 데이터 소스에 쉽게 연결</span>
                  <span>– IoT Stack에 의한 데이터 소스에 쉽게 연결</span>
                </li>
                <li>
                  <span>보안성 / 안정성</span>
                  <span>– 데이터 암호화</span>
                  <span>– 보안전문 인력 보유</span>
                </li>
                <li>
                  <span>확장성</span>
                  <span>– Legacy연결을 위한 Bridge / Ext. 기능</span>
                  <span>– 오픈 플랫폼 기반</span>
                  <span>– 국제 IoT 표준규격 준수</span>
                </li>
              </ol> */}
            </div>
          </li>
          <li className="standServices1 ttaServicesLi">
            <div className="ttaStandardsImg elsaServicesImg1">
              <img src={tta_2} alt="" />
            </div>
            <div className="textWrap">
              <h2>모니터링 데이터 모델에 대한 표준</h2>
              <p className="bodyText">
                이 표준은 승강기 원격 모니터링을 지원하는 게이트웨이, 승강기,
                응용 서버에서 사용하는 모니터링 데이터 모델을 정의한다.
              </p>
              {/* <ol>
                <li>
                  <span>IoT/AI 프레임워크</span>
                  <span>– 데이터 수집, 분석 및 AI학습</span>
                  <span>
                    – 프로토콜 어댑터, 데이터 버스, 최신 Web/App서비스
                    컨테이너기반 프레임워크
                  </span>
                  <span>– Node 인증, 권한관리, SW배포 기능</span>
                </li>
                <li>
                  <span>유연성</span>
                  <span>– 사용량에 따른 과금</span>
                  <span>– 단시간에 서버 확장 가능</span>
                </li>
                <li>
                  <span>편리성</span>
                  <span>– 언제, 어디서든 인터넷을 통한 접근</span>
                  <span>– Secure 브라우즈 기반 데이터 접근</span>
                  <span>– PaaS 플랫폼에 의한 Private운영센터</span>
                </li>
              </ol> */}
            </div>
          </li>
          <li className="standServices2 ttaServicesLi">
            <div className="ttaStandardsImg elsaServicesImg2">
              <img src={tta_3} alt="" />
            </div>
            <div className="textWrap">
              <h2>ELMP-485프로토콜에 대한 표준</h2>
              <p className="bodyText">
                이 표준은 승강기 원격 모니터링을 위한 통신 프로토콜, ELMP-485를
                정의한다. 승강기 원격 모니터링 응용은 승강기 제어반들(ECPs,
                Elevator Control Panels), 승강기 감시반(EMP, Elevator Monitoring
                Panel), 승강기 모니터링 게이트웨이(EMG, Elevator Monitoring
                Gateway) 및 승강기 모니터링 서버(EMS, Elevator Monitoring
                Server) 간에 수행된다. 특히 이 표준은 게이트웨이와 승강기 제어반
                및 감시반 사이의 통신 프로토콜을 정의한다.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="sectionWrap standGallery ttaGallery">
        <div className="">
          <TtaCoverflowEffect />
        </div>
      </div>
    </div>
  </Layout>
)

export default TtaStand
